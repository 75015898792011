<!--商品属性-底部弹出组件-->
<template>
  <van-popup v-model="show" round position="bottom" :style="{ minHeight: '50%' }">
    <div class="bc-popup">
      <div class="bc-popup-title">
        {{ title }}
        <img src="@/assets/images/popup_close.png" mode="heightFix" style="width: 28px;height: 28px;" class="close-btn"
          @click="show=false" />
      </div>

      <div class="bc-popup-content">
        <div id="" v-for="(item, key) in dataList" :key="key">
          <div class="bc-m-row-32 bc-border-bottom"
            v-if="(baseChoice == '0' && item.insert_type == '3') || (baseChoice == '1' && item.insert_type == '2')">
            <div class="bc-color-grey bc-fz-32">
              {{ item.name }}
            </div>
            <div class="bc-row-start bc-align-start bc-flex-warp">
              <div :style="{'margin-right': index1 != 0 && index1 % 4 == 0 ? '':'8px'}"
                v-for="(item1,index1) in item.attr_value.split(',')" :key="index1" class="bc-rela bc-m-t-20"
                @click="preview(item.attr_value.split(','),index1)">
                <img :src="item1" style='width: 64px;height: 64px;' alt="">
              </div>
            </div>
          </div>
          <div class="bc-row-between bc-align-center bc-m-row-32 row-item bc-border-bottom" v-else>
            <div class="bc-color-grey bc-fz-32">
              {{ item.name }}
            </div>
            <div class="bc-fz-36">
              {{ item.attr_value }}
            </div>
          </div>
        </div>

      </div>

      <div class="btn bc-flex-center bc-fz-32" @click="show = false">完成</div>
    </div>
  </van-popup>
</template>

<script>
import {
  ImagePreview
} from 'vant'
export default {
  components: {
    [ImagePreview.Component.name]: ImagePreview.Component
  },
  computed: {
    show: {
      get () {
        return this.value
      },
      set (value) {
        this.$emit('input', value)
      }
    }
  },
  props: {
    value: {
      type: Boolean
    },
    title: {
      type: String,
      default: '商品属性'
    },
    dataList: {
      type: Array,
      default: () => []
    },
    baseChoice: {
      type: String,
      default: '0'
    }
  },
  data () {
    return {}
  },
  watch: {},
  methods: {
    close () {
      this.$emit('cancel')
      this.show = false
    },
    confirm () {
      this.$emit('confirm')
    },
    preview (list, index) {
      ImagePreview({
        images: list,
        startPosition: index
      })
    }
  }
}
</script>

<style lang="scss" scoped>
  .bc-popup {
    padding-top: 10px;

    .close-btn {
      position: absolute;
      right: 14px;
    }
  }

  .bc-popup-title {
    color: #202122;
    font-size: 18px;
    font-weight: 600;
    text-align: center;
    padding: 0 15px;
  }

  .bc-popup-content {
    height: 400px;
    overflow-y: auto;
    margin-top: 26px;
  }

  .row-item {
    height: 46px;
  }

  .btn {
    // position: fixed;
    // bottom: 0;
    width: 339px;
    height: 36px;
    background: #00a0ac;
    color: #fff;
    border-radius: 4px;
    margin: 10px auto;
  }
</style>
