import { render, staticRenderFns } from "./publicProductDetail.vue?vue&type=template&id=66be2fcb&scoped=true&"
import script from "./publicProductDetail.vue?vue&type=script&lang=js&"
export * from "./publicProductDetail.vue?vue&type=script&lang=js&"
import style0 from "./publicProductDetail.vue?vue&type=style&index=0&id=66be2fcb&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "66be2fcb",
  null
  
)

export default component.exports