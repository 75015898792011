<template>
  <div class="productDetail">
    <div v-if="status == '1'">

      <div class="swipe-box" :style="{height: clientWidth}">
        <van-swipe :style="{height: clientWidth}" :show-indicators='false' class="swipe" @change="onChange"
          :autoplay="3000" indicator-color="white">
          <van-swipe-item v-for='(item,index) in  product_info.imgs' :key='index'>
            <van-image :style="{height: clientWidth}" style="width:100%;" :src="item" :error-icon='errImgSrc'>
            </van-image>
          </van-swipe-item>
        </van-swipe>
        <div class="indicator" v-if='product_info.imgs.length'>
          {{current + 1}}/{{product_info.imgs.length}}
        </div>
      </div>

      <div class="info-box bc-white bc-m-b-10">
        <div class="bc-row-between bc-align-center">
          <div class="bc-row-start bc-align-center">
            <div class="price bc-row-center bc-align-center">
              <span class="bc-fz-30 bc-f-w-500">￥</span>
              <span>{{product_info.out_price}}</span>
            </div>
            <div class="bc-m-l-20 bc-color-gray bc-fz-24" v-if="product_info.postage > 0">
              运费{{product_info.postage}}元
            </div>
            <div v-else class="bc-m-l-20 bc-color-gray bc-fz-12">
              包邮
            </div>
          </div>
        </div>
        <div class="name bc-fz-16 bc-m-b-16">{{product_info.product_name}}</div>
      </div>

      <div class="norms bc-white bc-m-b-10" v-if="product_info && product_info.unit_data.length > 0">
        <div class="bc-row-between bc-align-center" @click='confirmPop = true'>
          <div>
            <span class="bc-m-r-10 bc-flex-noshrink">选择规格</span>
            <span class="txt bc-fz-13">{{product_info.desc}}</span>
          </div>

          <van-icon name="arrow" size="16" />
        </div>
      </div>

      <div class="norms bc-white bc-m-b-10" v-if="attributeList.length > 0">
        <div class="bc-row-between bc-align-center" @click='showAttribute = true'>
          <div class="bc-row-end bc-align-center" style="padding-right: 0;">
            <span class="bc-m-r-10 bc-flex-noshrink">商品属性</span>
            <span class="txt bc-fz-13 bc-line-1">{{ attributeStr }}</span>
          </div>

          <van-icon name="arrow" size="16" />
        </div>
      </div>

      <div class="bc-white bc-m-b-10" style="padding: 15px 15px;">
        <div class="bc-row-between">
          <div class="bc-fz-16 bc-f-w-5 bc-line-1" style="width: 200px;">
            {{product_info.org_name}}
          </div>
          <div class="bc-row-start">
            <div class="bc-row-start bc-align-center bc-m-r-20 btn-1" @click="toOrgDetail">
              <span class="bc-fz-10" style="color: #fff;">全部商品</span>
              <van-icon name="arrow" color="#fff" size="12" />
            </div>
            <div class="bc-p-row-5 bc-row-start bc-align-center btn-2" @click="toOrgDetail">
              <span class="bc-color-theme bc-fz-10">进店</span>
              <van-icon name="arrow" color="#00A0AC" size="12" />

            </div>
          </div>
        </div>
        <div style="margin-top:5px">
          <img v-if="vipTags[permission_type]" mode="heightFix" height="14" style="margin-right: 10px;height:14px"
            :src="vipTags[permission_type]" />
          <img style="margin-right:5px" v-for='(item,index) in authTags' :key='index' :src="item.icon"
            :style="{height:item.height}" mode="heightFix" />

        </div>
      </div>

      <div class="detail-box bc-white">
        <div class="title bc-m-b-10">商品详情 </div>
        <div class="bc-m-b-10" v-if='product_info.product_text'>【{{product_info.product_text}}】</div>
        <div class="describe-box" v-if="product_info.detail || product_info.details_imgs.length > 0">
          <van-field style="padding:0px" v-model="product_info.detail" rows="1" autosize disabled type="textarea" />
          <!-- <textarea name=""
                    contenteditable="true"
                    style="outline: none;border:none;width:100%;height:auto"
                    disabled
                    v-model="product_info.detail"></textarea> -->

          <div style="font-size: 0;">
            <img style="margin-right:5px" :src="path" v-for="(path,i) in product_info.details_imgs" :key="i"
              mode="heiwidthFixghtFix" />
          </div>
        </div>

        <div v-else>
          <van-empty description="暂无数据" />
        </div>
      </div>
      <div style="width:100%;height:70px"></div>
      <div class="bottom-btn">
        <div class="left">
          <a :href='`tel:${product_info.org_msg.org_mobile}`' class="l-item border">
            <van-icon style="transform: rotate(270deg);" name="phone-o" color="#000000" size='20' />
            <span>拨打电话</span>
          </a>
          <div class="l-item" @click="toOrgDetail">
            <van-icon name="shop-o" color="#000000" size='20' />
            <span>店铺</span>
          </div>
        </div>
        <div class="right" @click='confirmPop = true'>立即购买</div>
      </div>
    </div>
    <div v-if="status == '2' || status == '3'">
      <van-empty image="error" description="商品已下架">
        <van-button color="#00a0ac" @click="toOrgDetail" plain type="info">进入店铺</van-button>
      </van-empty>
    </div>
    <!-- 确认弹窗 -->
    <van-popup v-model="confirmPop" position="bottom" :closeable="true" :border-radius="20">
      <div class="pop-up product">
        <div class="product-info bc-m-b-30">
          <van-image style="width:80px;height:80px" :error-icon='errImgSrc' :src="product_info.imgs[0]">
          </van-image>
          <div class="bc-col-between bc-m-l-10">
            <div class="name bc-line-2 bc-fz-16">{{ product_info.product_name }}</div>
            <div class="price bc-fz-18 bc-f-w-500">
              <span class="bc-fz-14 text">￥</span>
              <span class="text">{{product_info.out_price}}</span>
            </div>
          </div>
        </div>

        <div class="unit-box bc-m-b-20"
          v-if="!(product_info.unit_data.length == 1 && !product_info.unit_data[0].unit_name)">
          <div class="unit-type bc-fz-14">规格</div>
          <div class="bc-flex-warp">
            <div class="unit-item bc-fz-16 bc-radius-4 bc-align-center" :class="{active: index == select_unit_index1}"
              v-for="(item, index) in product_info.unit_data" :key="index" @click="set_select_unit_index(index)">
              {{item.unit_name}}
            </div>
          </div>
        </div>

        <div class="unit-box bc-m-b-20"
          v-if='product_info.unit_data[select_unit_index1] && product_info.unit_data[select_unit_index1].unit_list && product_info.unit_data[select_unit_index1].unit_list.some(el => el.unit_value)'>
          <div class="unit-type bc-fz-14">单位</div>
          <div class="bc-flex-warp">
            <div class="unit-item bc-fz-16 bc-radius-8 bc-align-center" :class="{active: index == select_unit_index2}"
              @click="set_select_unit_index2(index)"
              v-for="(item, index) in product_info.unit_data[select_unit_index1].unit_list" :key="index">
              {{item.unit_value}}
            </div>
          </div>
        </div>

        <div class="number-box bc-row-between bc-p-col-15 bc-m-b-10">
          <span class="text">购买数量</span>
          <van-stepper :min="1" v-model="select_num" />
        </div>

        <div class="bc-row-start" style="height: 40px;border-radius: 4px;overflow: hidden;">
          <div class="bc-row-center bc-align-center bc-flex-1" style="background: #0BA8A6;color: #fff;"
            @click="confirmPopup">
            立即购买
          </div>
        </div>
      </div>
    </van-popup>
    <!-- 商品属性 -->
    <product-attr-popup v-model="showAttribute" :baseChoice='product_info.base_choice' :dataList="attributeList" />
  </div>
</template>

<script>
import {
  getProductDetail,
  orgBaseDetail
} from '@/api/public.js'

import productAttrPopup from './productAttrPopup.vue'
export default {
  components: {
    productAttrPopup
  },
  props: {},
  data () {
    return {
      clientWidth: '',
      errImgSrc: require('../../assets/images/err-img.png'),
      status: '',
      confirmPop: false,
      product_id: '',
      unit_id: '',
      select_num: 1,
      select_unit_index1: 0,
      select_unit_index2: 0,
      unit_data: [],
      freight_arr: [],
      product_info: {
        attr_data: [],
        details_imgs: [],
        org_msg: {
          org_mobile: ''
        },
        imgs: [],
        unit_data: [{
          unit_list: []
        }],
        base_choice: '0',
        base_attr: [],
        base_img_attr: []
      },
      current: 0,
      authTags: [],
      permission_type: '',
      vipTags: [
        require('../../assets/auth/vip1.png'),
        require('../../assets/auth/vip2.png'),
        require('../../assets/auth/vip3.png')
      ],
      auth: [{
        id: '109',
        name: '企业规模认证',
        height: '20px',
        // icon: '/assets/auth/qygm3.png',
        icon: [
          require('../../assets/auth/qygm1.png'),
          require('../../assets/auth/qygm2.png'),
          require('../../assets/auth/qygm3.png')
        ]
      },
      {
        id: '105',
        name: '原产地',
        icon: require('../../assets/auth/ycd.png'),
        height: '420px'
      },
      {
        id: '106',
        name: '无公害农产品',
        icon: require('../../assets/auth/wgh.png'),
        height: '20px'
      },
      {
        id: '107',
        name: '有机农产品',
        icon: require('../../assets/auth/yj.png'),
        height: '20px'
      },
      {
        id: '102',
        name: '实名认证',
        icon: require('../../assets/auth/smrz.png'),
        height: '20px',
        width: 16
      },

      {
        id: '101',
        name: '营业执照',
        icon: require('../../assets/auth/yyzz.png'),
        height: '20px',
        width: 16
      },
      {
        id: '103',
        name: '特许经营资质',
        icon: require('../../assets/auth/txjyzz.png'),
        height: '20px',
        width: 16
      },
      {
        id: '108',
        name: '信用等级',
        height: '20px',
        // icon: '/assets/auth/xydj5.png',
        icon: [
          require('../../assets/auth/xydj1.png'),
          require('../../assets/auth/xydj2.png'),
          require('../../assets/auth/xydj3.png'),
          require('../../assets/auth/xydj4.png'),
          require('../../assets/auth/xydj5.png')

        ]
      }
      ],
      showAttribute: false, // 商品属性弹窗
      attributeList: [], // 商品属性列表
      attributeStr: ''
    }
  },
  watch: {},
  computed: {},
  methods: {
    _getProductDetail () {
      getProductDetail({
        product_id: this.$route.query.product_id
      }).then(res => {
        console.log(res)
        document.title = res.product_name
        this.status = res.status
        this.setAuthIcon(res.org_msg)
        const unit_data = []
        res.unit_data.forEach(item => {
        	const unit_name = item.unit_name
        	const sItem = {
        		flagship_price_list: item.flagship_price_list,
        		unit_name: item.unit_name,
        		application: item.application,
        		activity_price: item.activity_price,
        		unique_id: item.unique_id,
        		custom_attr: item.custom_attr,
        		unit_id: item.unit_id,
        		unit_value: res.unit_data_dict[item.unit_name].filter(fItem => {
        			return item.unit_id == fItem.unit_id
        		})[0].unit_value
        	}
        	const index = unit_data.findIndex(fItem => {
        	  return fItem.unit_name == unit_name
        	})
        	if (index > -1) {
        	  unit_data[0].unit_list.push(sItem)
        	} else {
        	  unit_data.push({
        	    	unit_name: unit_name,
        	    	unit_list: [sItem] // 过滤无规格
        	  })
        	}
        })
        // for (const key in res.unit_data_dict) {
        //   const unit_name = key
        //   const unit_list = res.unit_data_dict[key].map(item1 => {
        //     const index = res.unit_data.findIndex(item2 => {
        //       return item1.unit_id == item2.unit_id
        //     })
        //     if (index > -1) {
        //       item1.flagship_price_list = res.unit_data[index].flagship_price_list
        //       item1.unit_name = key
        //       item1.application = res.unit_data[index].application
        //       item1.activity_price = res.unit_data[index].activity_price
        //       item1.unique_id = res.unit_data[index].unique_id
        //       item1.custom_attr = res.unit_data[index].custom_attr
        //     }
        //     return item1
        //   })
        //   unit_data.push({
        //     unit_name: unit_name,
        //     unit_list: unit_list // 过滤无规格
        //   })
        // }

        this.select_unit_index1 = unit_data.findIndex(el => {
          return Array.isArray(el.unit_list) && el.unit_list.some(item => item.unit_id === this
            .$route.query.unit_id)
        })

        if (this.select_unit_index1 > -1 && unit_data[this.select_unit_index1].unit_list) {
          this.select_unit_index2 = unit_data[this.select_unit_index1].unit_list.findIndex(el => el
            .unit_id === this.$route.query.unit_id)
        }

        const select_unit_data = unit_data[this.select_unit_index1].unit_list[this.select_unit_index2]
        this.product_info = {
          status: res.status,
          unit_data: unit_data,
          imgs: res.imgs.length == 0 ? ['/static/images/err-img.png'] : res.imgs,
          attr_data: res.attr_data,
          unique_id: select_unit_data.unique_id,
          activity_price: select_unit_data.activity_price,
          out_price: select_unit_data.flagship_price_list[0].out_price,
          package_price: select_unit_data.flagship_price_list[0].package_price,
          product_name: res.product_name,
          org_name: res.org_msg.org_name,
          org_id: res.org_id,
          detail: res.detail,
          details_imgs: res.details_imgs,
          desc: select_unit_data.unit_name,
          postage: Number(select_unit_data.flagship_price_list[0].package_price) -
              Number(select_unit_data.flagship_price_list[0].out_price),
          create_by: res.create_by,
          product_text: res.product_text,
          org_mobile: res.org_mobile,
          org_msg: res.org_msg,
          base_choice: res.base_choice,
          base_attr: res.base_attr,
          base_img_attr: res.base_img_attr
        }
        this.setAttribute()
        console.log(this.product_info)
      })
    },
    // 选择规格
    set_select_unit_index (index) {
      this.select_unit_index1 = index
      this.select_unit_index2 = 0
      this.set_product_info()
      this.setAttribute()
    },
    // 选择规格
    set_select_unit_index2 (index) {
      this.select_unit_index2 = index
      this.set_product_info()
      this.setAttribute()
    },
    // 设置商品属性
    setAttribute () {
      const select_unit_data = this.product_info.unit_data[this.select_unit_index1].unit_list[this
        .select_unit_index2]
      // 商品属性： base_attr + 对应规格的custom_attr
      if (this.product_info.base_choice == '0') {
        this.attributeList = [...this.product_info.base_attr, ...select_unit_data.custom_attr]
        this.attributeStr = this.attributeList.filter(item => {
          return item.insert_type != '3'
        }).map(item => {
          return item.attr_value
        }).join(',')
      }
      if (this.product_info.base_choice == '1') {
        this.attributeList = [...this.product_info.base_img_attr, ...select_unit_data.custom_attr]
        this.attributeStr = this.attributeList.filter(item => {
          return item.insert_type != '2'
        }).map(item => {
          return item.attr_value
        }).join(',')
      }
    },
    confirmPopup () {
      this.confirmPop = false
      // if (this.isSelf()) {
      //   return this.$toast('不能购买自己的商品')
      // }
      const unit_id = this.product_info.unit_data[this.select_unit_index1].unit_list[this.select_unit_index2]
        .unit_id
      const p = [{
        seller_user_code: this.product_info.create_by,
        seller_org_id: this.$route.query.org_id,
        pro_list: [{
          unit_id: unit_id,
          pro_sum: this.select_num,
          seller_source: '2'
        }]
      }]
      localStorage.setItem('pro_list', JSON.stringify(p))
      this.confirmPop = false
      var host = window.location.host
      let url = 'http://192.168.0.193:8080/#'
      let shareUrl = ''
      const params = ''
      if (host === 't-static-h5.tevv.com.cn') {
        url = 'http://t-static-h5.tevv.com.cn/#' // 测试域名
        shareUrl = 'http://t-nz-app.tevv.com.cn' // 测试域名
      } else if (host === 'p-static-h5.tevv.com.cn') {
        url = 'https://p-static-h5.tevv.com.cn/#' // 正式域名
        shareUrl = 'https://p-nz-app.tevv.com.cn' // 正式域名
      } else if (host === 'r-static-h5.tevv.com.cn') {
        url = 'http://r-static-h5.tevv.com.cn/#' // 正式域名
        shareUrl = 'http://r-nz-app.tevv.com.cn' // 正式域名
      }
      const link = `${url}/confirmOrder?product_id=${this.$route.query.product_id}`
      const shareLink = `${shareUrl}/auth?back_url=${encodeURIComponent(link)}`
      location.href = shareLink
      // this.$router.push({
      //   path: '/confirmOrder',
      //   query: {
      //     product_id: this.$route.query.product_id,
      //     open_id: this.$route.query.openid
      //   }
      // })
    },
    // 设置店铺信息
    set_product_info () {
      const select_unit_data = this.product_info.unit_data[this.select_unit_index1].unit_list[this
        .select_unit_index2]
      this.product_info.desc = select_unit_data.unit_name
      this.product_info.unique_id = select_unit_data.unique_id
      this.product_info.activity_price = select_unit_data.activity_price
      this.product_info.out_price = select_unit_data.flagship_price_list[
        0].out_price
      this.product_info.package_price = select_unit_data
        .flagship_price_list[0].package_price
      this.product_info.postage = Number(select_unit_data.flagship_price_list[
        0].package_price) - Number(select_unit_data.flagship_price_list[
        0].out_price)
      console.log(this.product_info)
    },
    // 设置店铺图标
    setAuthIcon (data) {
      this.auth.forEach((item) => {
        const id = item.id
        const tag = data.authentications[id]
        if (tag) {
          if (id === '109') {
            const level = [
              '小微企业 营业额3000万以下',
              '中型企业 营业额3000~6000万',
              '大型企业 营业额6000万以上'
            ]
            const index = level.indexOf(tag.level)
            this.authTags.push({
              icon: item.icon[index],
              height: item.height
            })
          } else if (id === '108') {
            this.authTags.push({
              icon: item.icon[tag.level.length - 1],
              height: item.height
            })
          } else {
            this.authTags.push({
              icon: item.icon,
              height: item.height
            })
          }
        }
      })
    },
    onChange (index) {
      this.current = index
    },
    // 获取店铺等级信息
    async _orgBaseDetail () {
      const res = await orgBaseDetail({
        org_id: this.$route.query.org_id
      })
      this.permission_type = res.permission_type
    },
    toOrgDetail () {
      this.$router.push({
        path: '/orgDetail',
        query: {
          org_id: this.$route.query.org_id

        }
      })
    }
  },
  created () {
    document.title = '商品详情'
    this.clientWidth = `${document.documentElement.clientWidth}px`
    this._getProductDetail()
    this._orgBaseDetail()
    console.log('openid:' + this.$route.query.openid)
  },
  mounted () {}
}
</script>
<style lang="scss" scoped>
  .bottom-btn {
    position: fixed;
    height: 60px;
    bottom: 0px;
    right: 0px;
    left: 0px;
    background: #fff;
    display: flex;

    align-items: center;
    justify-content: space-between;

    .left {
      flex: 1;
      display: flex;
      align-items: center;

      .l-item {
        flex: 1;
        display: flex;
        align-items: center;
        justify-content: center;

        span {
          color: #000000;
          font-size: 12px;
          margin-left: 6px;
        }
      }

      .border {
        border-right: 1px solid #dddddd;
      }
    }

    .right {
      width: 170px;
      height: 36px;
      background: #ff5e18;
      border-radius: 18px;
      text-align: center;
      line-height: 36px;
      color: #fff;
      font-size: 16px;
      font-weight: 500;
      margin-right: 10px;
    }
  }

  .productDetail {
    width: 100%;
    min-height: 100%;
    background: #f1f1f1;
  }

  .swipe-box {
    width: 100%;
    position: relative;
  }

  .swipe {
    width: 100%;
  }

  .indicator {
    padding: 3px 8px;
    line-height: 1;
    background-color: rgba(0, 0, 0, 0.3);
    border-radius: 50px;
    font-size: 13px;
    color: rgba(255, 255, 255, 0.8);
    position: absolute;
    bottom: 10px;
    right: 10px;
    z-index: 99;
  }

  .info-box {
    padding: 10px 15px;
    border-bottom: 2rpx solid #f3f3f3;

    .price {
      span {
        color: #ff3e16;
        font-size: 20px;
        display: block;
        margin-bottom: 8px;
      }
    }

    .name {
      color: #373737;
      font-weight: bold;
    }
  }

  .norms {
    padding: 15px 15px;

    div {
      &:not(:last-child) {
        border-bottom: 2rpx solid #f5f5f5;
      }

      span {
        color: #333333;

        &.bc-jiantou_you {
          color: #9a9a9a;
        }
      }

      .lbs {
        color: #000;
      }

      .spns {
        color: #888;
        float: right;
      }
    }
  }

  .btn-1 {
    background: #00a0ac;
    height: 20px;
    line-height: 20px;
    border-radius: 10px;
    padding: 0px 5px;
    font-size: 14px;

    span {
      font-size: 12px;
    }
  }

  .btn-2 {
    background-color: #e4f5f6;
    height: 20px;
    line-height: 20px;
    border-radius: 10px;
    padding: 0px 5px;
    font-size: 14px;

    span {
      font-size: 12px;
    }
  }

  .detail-box {
    padding: 10px 16px 10px 16px;

    .title {
      color: #212121;
    }

    .describe-box {
      .describe {
        color: #333333;
      }

      img {
        width: 100%;
      }
    }
  }

  .pop-up {
    padding: 10px 0 10px 10px;
    color: #333333;

    &.product {
      padding: 16px 16px 16px 16px;
    }

    .pop-box {
      display: flex;

      .pop-item {
        width: 80px;
        height: 80px;
        background: #eeeeee;

        img {
          width: 23px;
          height: 23px;
        }

        .text {
          color: #333333;
        }
      }
    }

    .product-info {
      display: flex;

      div {
        width: 234px;
      }

      .name {
        color: #333333;
      }

      .price {
        color: #ff3e16;
        display: flex;
        align-items: center;
      }
    }

    .unit-box {
      view {
        display: flex;
      }

      .unit-type {
        color: #333333;
      }

      .unit-item {
        display: inline-block;
        padding: 4px 8px;
        margin: 4px 8px 4px 0;
        background: #e9e9e9;

        &.active {
          border: 1px solid #0ba8a6;
          color: #111;
          background: #f5f7fa;
        }
      }
    }
  }
</style>
