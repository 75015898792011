<template>
  <div class="confirmOrder">
    <div class="from">
      <div class="box">
        <div class="title">收货地址</div>
        <div class="from-item">
          <van-field required
                     v-model="order_address.name"
                     label="收货人姓名"
                     placeholder="请填写收货人姓名"
                     input-align="right" />
        </div>
        <div class="from-item">
          <van-field required
                     v-model="order_address.phone"
                     label="联系电话"
                     placeholder="请填联系电话"
                     input-align="right" />
        </div>
        <div class="from-item">
          <van-field readonly
                     clickable
                     required
                     name="picker"
                     :value="areaSrt"
                     label="选择地区"
                     placeholder="选择省市区"
                     input-align="right"
                     @click="showPicker = true" />
        </div>
        <div class="from-item">
          <van-field required
                     type="textarea"
                     v-model="order_address.address_detail"
                     label="详细地址"
                     placeholder="如街道/小区/乡镇/村"
                     input-align="right" />
        </div>
        <div class="tips">未注册的手机号码登录后将自动创建宝秤账号</div>
      </div>
    </div>

    <div class="from">
      <div class="box"
           v-for="(item, index) in dataList"
           :key="index">
        <div class="title">{{item.seller_info.org_name}}</div>
        <div class="product bc-row-start bc-full-x bc-p-col-12"
             v-for="(pItem, pIndex) in item.pro_list"
             :key="pIndex">
          <div class="product-item bc-row-start bc-flex-1">
            <van-image width='54'
                       height='54'
                       :src="pItem.imgs"
                       :error-icon='errImgSrc'>
            </van-image>

            <!-- <img :src="pItem.imgs"
                 :οnerrοr="errorImg(this)"
                 style="width:54px;height:54px;border-radius:8px"> -->

            <div class="bc-col-star bc-m-l-12 bc-flex-1">
              <div class="bc-row-between">
                <div class="name bc-line-2 bc-fz-32 bc-flex-2">
                  {{pItem.product_name}}
                </div>
                <div class="bc-col-between">
                  <span>￥{{pItem.price}}</span>
                  <span class="price-number"></span>
                </div>
              </div>
              <div class="unit bc-fz-28 bc-m-t-4">
                <span>{{pItem.spec}}</span>
                <span> x {{pItem.qty}}</span>
              </div>
            </div>
          </div>
        </div>
        <div class="bc-row-end bc-p-b-12">
          <a :href='`tel:${item.seller_info.phone}`'
             class="call">
            <van-icon name="phone-o"
                      color="#00A0AC"
                      size='14' />
            <span>拨打电话</span>
          </a>
        </div>
        <div class="bc-row-end bc-p-col-16"
             style="border-top: 0.5px solid #dddddd;">
          <span class="total-price bc-fz-14"
                style="color: #555555;">共{{typeNum}}种 {{allNum}}件, 订单总额:</span>
          <div v-if='price.total'
               class="price bc-m-l-4">{{price.total.total}}</div>
        </div>
      </div>
    </div>
    <div class="bottom-btn">
      <div class="bc-row-start bc-align-center">
        <div style="color: #202122;">
          <div class="bc-fz-10"
               style="color: #202122;">应付总额</div>
          <div class="bc-fz-10"
               style="color: #202122;">(含邮费)</div>
        </div>
        <div class="bc-m-l-6"
             style="color:#FA675C"
             v-if='price.total'>
          <span class="bc-fz-12">￥</span>
          <span class="bc-fz-16">{{price.total.total}}</span>
        </div>
      </div>
      <div class="btn"
           @click="confirmOrder">提交订单</div>
    </div>
    <van-popup v-model="showPicker"
               position="bottom">
      <van-cascader v-model="cascaderValue"
                    active-color="#00a0ac"
                    title="选择省市区"
                    :options="options"
                    :field-names="fieldNames"
                    @close="showPicker = false"
                    @finish="confirm" />
    </van-popup>
  </div>

</template>

<script>
import { getProduct, getPriceCount, getAllAddress, moblieLogin, receiveAddress, createBusinessOrder } from '@/api/public.js'
export default {
  components: {},
  props: {},
  data () {
    return {
      errImgSrc: require('../../assets/images/err-img.png'),
      showPicker: false,
      value: '',
      cascaderValue: '',
      typeNum: 0,
      allNum: 0,
      dataList: [],
      price: {},
      options: [],
      order_address: {
        address_detail: '',
        address: "",
        address_id: "",
        latitude: "",
        longitude: "",
        name: "",
        phone: "",
        send_time: "工作日、双休日或假日均可送货",
      },
      fieldNames: {
        text: 'name',
        value: 'code',
        children: 'children',
      },
      params: {
        damage_bear: 'seller',
        freight_bear: 'seller',
        logistics_freight_pay: 'seller'
      },
      userInfo: {},
      seller_info: {},
      areaSrt: ''
    }
  },
  watch: {},
  computed: {},
  methods: {
    async confirmOrder () {
      if (!this.order_address.name) {
        this.$toast('请填写收货人姓名')
        return
      }
      if (!this.order_address.phone) {
        this.$toast('请填写联系电话')
        return
      }

      if (!/^(?:(?:\+|00)86)?1[3-9]\d{9}$/.test(this.order_address.phone)) {
        this.$toast('手机号码格式错误')
        return
      }
      if (!this.areaSrt) {
        this.$toast('请选择地区')
        return
      }
      if (!this.order_address.address_detail) {
        this.$toast('请填写详细地址')
        return
      }
      const loading = this.$loading({
        lock: true,
        text: '提交订单中',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      })
      let lRes = await moblieLogin({
        mobile: this.order_address.phone
      })
      console.log(lRes)
      this.userInfo = lRes

      let aRes = await receiveAddress({
        "address_code": "", //就这样
        "detailed_address": `${this.areaSrt} ${this.order_address.address_detail}`,
        "user_code": lRes.user_code, //用户user_code
        "is_default": false, //就这样
        "latitude": "", //就这样
        "longitude": "", //就这样
        "receive_name": this.order_address.name, //收货人名称
        "receive_phone": this.order_address.phone, //手机号
        "remarks": "", //就这样
        "status": "1"  //就这样
      })
      console.log(aRes)
      let param = [];
      this.dataList.forEach((element) => {
        param.push({
          express_amount: this.price[element.seller_org_id].summary.express, // 物流运费
          order_seller_code: element.seller_user_code || '', // 卖家user_Cdoe
          order_seller_org_id: element.seller_org_id || '', // 卖家机构id
          order_buyer_code: this.userInfo.user_code, //买家user_code
          pay_type_time: '', //账期结束时间
          order_buyer_org_id: this.userInfo.org_id, //买家机构id
          logistics_freight_pay: this.params.logistics_freight_pay, // 费支付方  buyer/seller
          free_shipping: '0', // 是否包邮 '0'不包邮, '1'包邮
          freight_bear: this.params.freight_bear, // 物运输费承担方 buyer/seller
          short_logistics: 'seller', // 短途运输费承担方  buyer/seller
          insurance_bear: 'seller', //货物保险费承担方  buyer/seller
          order_channel: 'WX', //下单渠道  目前只有Android，后期可扩展IOS，WEB等渠道
          damage_bear: this.params.freight_bear, // 货损责任承担方  buyer/seller
          shipping_method: '', //配送方式  take自提/self-support:自营物流/third_party:第三方物流
          shipping: '1', //配送方式  
          pay_type: '1',
          order_address: {
            address: aRes.detailed_address,
            address_id: aRes.address_id,
            latitude: aRes.latitude,
            longitude: aRes.longitude,
            name: aRes.receive_name,
            phone: aRes.receive_phone,
            send_time: "工作日、双休日或假日均可送货"
          },
          order_production: element.pro_list,
        })
      })
      console.log(param)
      createBusinessOrder({
        orders: param,
      }).then(data => {
        loading.close()
        this.$router.push({
          path: '/pay',
          query: {
            code: data,
            amount: this.price.total.total,
            open_id: this.$route.query.openid,
            org_id: this.userInfo.org_id,
            receipt_org_id: this.seller_info.org_id,
            payType: 'confrimOrder',
            payment_type: '51',
            remark: '公域商品下单'
          }
        })
      }).catch((error) => {
        loading.close()
        this.$toast(error.msg)
        console.log(error.msg)
      })


    },
    confirm (value) {
      console.log(value)
      this.areaSrt = value.selectedOptions.map(item => {
        return item.name
      }).join('/')
      this.showPicker = false;
    },
    async getDataList () {
      console.log(localStorage.getItem('pro_list'))
      let pro_list = JSON.parse(localStorage.getItem('pro_list'))
      console.log(pro_list)
      pro_list.forEach(async (item) => {
        let res = await getProduct(item, 1)
        console.log(res, "res")

        res.seller_user_code = item.seller_user_code
        res.seller_org_id = item.seller_org_id
        res.pro_list.forEach((pItem, pIndex) => {
          // 固定商品替换
          if (item.pro_list[pIndex].product) {
            let f_item = item.pro_list[pIndex].product
            // 替换
            pItem.capacity = f_item.capacity
            pItem.capacity_unit = f_item.capacity_unit
            pItem.imgs = f_item.imgs
            pItem.price = item.pro_list[pIndex].price
            pItem.product_name = f_item.product_name
            pItem.single_text = f_item.single_text
            pItem.capacity = f_item.capacity
            pItem.sale_price = item.pro_list[pIndex].price
            pItem.spec = f_item.desc
            pItem.unit_name = f_item.unit_name
            pItem.unit_type = f_item.unit_type
            pItem.unit_value = f_item.unit_value
            pItem.unit_weight = f_item.unit_weight
            pItem.whole_text = f_item.whole_text
            pItem.unit_quantity = f_item.unit_quantity
            //默认值
            pItem.amount = '0.00'
            pItem.coupon_discount_price = '0.00'
            pItem.express_price = '0.00'
            pItem.msg_id = ''
            pItem.order_discount_price = '0.00'
            pItem.outbound_qty = '0'
            pItem.rule_data_str = ''
            pItem.unique_id = ''
          }

        })
        this.dataList.push(res)
        this.seller_info = res.seller_info
        console.log(this.sellerInfo)
        this.typeNum += res.pro_list.length;
        res.pro_list.forEach((pItem) => {
          this.allNum += Number(pItem.qty);
        })
      })
      console.log(this.dataList)
      this.getPrice()
    },
    async getPrice () {
      let units = []
      let pro_list = JSON.parse(localStorage.getItem('pro_list'))
      pro_list.forEach(element => {
        element.pro_list.forEach(el => {
          units.push({
            price: el.price ? el.price : '',
            unit_id: el.unit_id,
            qty: el.pro_sum
          })
        })
      });
      let res = await getPriceCount({
        units: units
      })
      console.log(res)
      this.price = res.data.data;

    }
  },
  created () {
    document.title = '确认订单'
    this.getDataList()
    getAllAddress({
      region_level: "3"
    }).then(res => {
      console.log(res.data.data)
      this.options = res.data.data
    })
  },
  mounted () { }
};
</script>
<style lang="scss" scoped>
.bottom-btn {
  position: fixed;
  bottom: 0px;
  left: 0px;
  right: 0px;
  height: 60px;
  background: #fff;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0px 15px;
  .btn {
    width: 125px;
    height: 40px;
    background: #ff5e18;
    border-radius: 20px;
    text-align: center;
    line-height: 40px;
    color: #fff;
  }
}
.confirmOrder {
  width: 100%;
  min-height: 100%;
  background: #f1f1f1;
  .from {
    padding: 10px;
    .box {
      background: #fff;
      border-radius: 4px;
      padding: 0px 8px;
      .title {
        font-size: 15px;
        font-weight: 500;
        color: #202122;
        padding: 15px 0px;
        border-bottom: 0.5px solid #dddddd;
      }
      .from-item {
        padding: 5px 0px;
        border-bottom: 0.5px solid #dddddd;
      }
      .tips {
        color: #a3a3a3;
        font-weight: 500;
        padding: 8px 0px;
        font-size: 10px;
      }
      .product {
        .product-item {
          .name {
            color: #303133;
          }

          .unit {
            display: flex;
            justify-content: space-between;
            height: 20px;
            line-height: 20px;
            color: #909399;
          }

          .price-number {
            margin-left: 3px;
          }
        }
      }
      .price {
        color: #fa675c;

        &::before {
          content: "￥";
          font-size: 24rpx;
        }
      }
      .call {
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 12px;
        border: 0.5px solid #a3a3a3;
        border-radius: 4px;
        padding: 2px;
      }
    }
  }
}
</style>